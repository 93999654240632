<template>
  <div class="ad-home">
    <ad-header>
      <div class="banner-text-container">
        <div class="banner-text">
          <div class="en-title">
            <img src="../assets/images/banner-text0.png" />
          </div>
          <div class="line">
            <img src="../assets/images/pic_rectangular.png" />
          </div>
          <p>想换好工作用灵鸽，让猎头帮你找</p>
        </div>
        <!-- <div class="banner-download">
          <div></div>
          <div @click="onDownload('android')">
            <img src="../assets/images/icon-android.png" />
            <span>Android 下载</span>
          </div>
          <div @click="onDownload('ios')">
            <img src="../assets/images/icon-ios.png" />
            <span>iphone 下载</span>
          </div>
        </div> -->
      </div>
      <div class="banner-text-container-mobile">
        <img src="../assets/images/mobile/banner-text.png" />
        <div class="line">
          <img src="../assets/images/pic_rectangular.png" />
        </div>
        <p>想换好工作用灵鸽，让猎头帮你找</p>
        <div class="btn-box">
          <div class="btn" @click="onDownload('mobile')">立即下载</div>
          <img class="to" src="../assets/images/mobile/to.png" />
        </div>
      </div>
    </ad-header>

    <ad-navs></ad-navs>

    <div class="advantage-container">
      <div class="title">
        <div class="en-title">
          <img src="../assets/images/advantage 2.png" />
        </div>
        <div class="cn-title">我们的优势</div>
      </div>
      <div class="advantage-list">
        <div class="advantage-item">
          <img src="../assets/images/home/icon_advantage_01.png" />
          <div class="content">
            <h3>链接专业猎头</h3>
            <p>
              完善简历后，系统自动为您推荐所属行业优秀猎头，您可根据情况选择授权委托多名猎头帮您找工作。
            </p>
          </div>
        </div>
        <div class="advantage-item">
          <img src="../assets/images/home/icon_advantage_02.png" />
          <div class="content">
            <h3>告别盲目海投</h3>
            <p>
              猎头根据您的情况及要求挑选合适的企业及岗位进行推荐，告别盲目海投，应聘成功率更高。
            </p>
          </div>
        </div>
        <div class="advantage-item">
          <img src="../assets/images/home/icon_advantage_03.png" />
          <div class="content">
            <h3>获取更优薪酬</h3>
            <p>资深猎头背书，帮助候选人谈薪，避免尴尬，争取更高薪水。</p>
          </div>
        </div>
        <div class="advantage-item">
          <img src="../assets/images/home/icon_advantage_04.png" />
          <div class="content">
            <h3>免费增值服务</h3>
            <p>专业猎头免费为候选人提供行业分析、职业规划、简历优化、面试辅导等服务。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="advantage-container-mobile">
      <img src="../assets/images/mobile/womendeyoushibg.png" style="width: 100%" />
      <div class="title">
        <div class="en-title">
          <img src="../assets/images/mobile/advantage-title-en.png" />
        </div>
        <div class="cn-title">我们的优势</div>
      </div>
      <div class="advantage-list">
        <div class="advantage-item">
          <img src="../assets/images/home/icon_advantage_01.png" />
          <div class="content">
            <h3>链接专业猎头</h3>
            <p>
              完善简历后，系统自动为您推荐所属行业优秀猎头，您可根据情况选择授权委托多名猎头帮您找工作。
            </p>
          </div>
        </div>
        <div class="advantage-item">
          <img src="../assets/images/home/icon_advantage_02.png" />
          <div class="content">
            <h3>告别盲目海投</h3>
            <p>
              猎头根据您的情况及要求挑选合适的企业及岗位进行推荐，告别盲目海投，应聘成功率更高。
            </p>
          </div>
        </div>
        <div class="advantage-item">
          <img src="../assets/images/home/icon_advantage_03.png" />
          <div class="content">
            <h3>获取更优薪酬</h3>
            <p>资深猎头背书，帮助候选人谈薪，避免尴尬，争取更高薪水。</p>
          </div>
        </div>
        <div class="advantage-item">
          <img src="../assets/images/home/icon_advantage_04.png" />
          <div class="content">
            <h3>免费增值服务</h3>
            <p>专业猎头免费为候选人提供行业分析、职业规划、简历优化、面试辅导等服务。</p>
          </div>
        </div>
      </div>
    </div>

    <div class="client-container">
      <div class="client">
        <div class="title">
          <div class="en-title">
            <img src="../assets/images/clients 2.png" />
          </div>
          <div class="cn-title">合作企业</div>
        </div>
        <div class="client-list" v-if="jsonData && jsonData.partners.length > 0">
          <div
            class="client-item"
            v-for="(item, index) in jsonData.partners"
            :key="index"
          >
            <img :src="item" />
          </div>
        </div>
      </div>
    </div>
    <div class="client-container-mobile">
      <img src="../assets/images/mobile/hezuoqiyebg.png" style="width: 100%" />
      <img
        src="../assets/images/mobile/hezuoqiye1bg.png"
        style="100%"
        class="client-mobile-bg"
      />
      <div class="client">
        <div class="title">
          <div class="en-title">
            <img src="../assets/images/mobile/clients-title-en.png" />
          </div>
          <div class="cn-title">合作企业</div>
        </div>
        <div class="client-list" v-if="jsonData && jsonData.partners.length > 0">
          <div
            class="client-item"
            v-for="(item, index) in jsonData.partners"
            :key="index"
          >
            <img :src="item" />
          </div>
        </div>
      </div>
    </div>

    <div class="job-container">
      <div class="title">
        <div class="en-title">
          <img src="../assets/images/job vacancy 2.png" />
        </div>
        <div class="cn-title">急招岗位</div>
      </div>
      <div class="job-list" v-if="jsonData && jsonData.positions.length > 0">
        <div
          class="job-item"
          v-for="(item, index) in jsonData.positions"
          :key="index"
          @click="onJobHandller(item)"
        >
          <div class="job-name">
            <div>{{ item.name }}</div>
            <p>{{ item.salary }}</p>
          </div>
          <div class="desc">{{ item.description }}</div>
          <div class="company">
            <p class="name"><img :src="item.company_logo" />{{ item.company_name }}</p>
            <p class="icon"><img src="../assets/images/icon-right.png" /></p>
          </div>
        </div>
      </div>
    </div>

    <ad-footer></ad-footer>

    <ad-float-download></ad-float-download>

    <el-dialog :visible.sync="jobDialogVisible" custom-class="job-dialog" width="360px">
      <div class="job-detail-contailer">
        <img src="../assets/images/icon-download.png" />
        <p>扫码下载APP，了解更多岗位详情</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "AdHomeComponent",
  data() {
    return {
      jobDialogVisible: false,
      jsonData: null,
      img: null,
    };
  },
  created() {
    let ran_time = Math.random() * 1000 + 100;
    this.$http
      .get(`https://cdn2.ringle.com/web/index.json?ts=${ran_time}`, {
        baseURL: "",
      })
      .then((result) => {
        this.jsonData = result;
      });
  },
  methods: {
    onJobHandller() {
      this.jobDialogVisible = true;
    },
    onDownload(platform) {
      if (platform === "mobile") {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //g
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isAndroid) {
          window.open(this.jsonData.app_url["android"]);
        }
        if (isIOS) {
          window.open(this.jsonData.app_url["ios"]);
        }
        return;
      }
      window.open(this.jsonData.app_url[platform]);
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .ad-home {
    .banner-text-container {
      display: none !important;
    }
    .banner-text-container-mobile {
      display: block !important;
      height: 100%;
      position: relative;
      img {
        display: block;
        width: 328px;
        height: 112px;
        margin-left: 17px;
        padding-top: 4.06rem !important;
      }
      .line {
        width: 313px;
        margin: 24px 0px 25px 17px !important;
        padding: 0px !important;
        img {
          width: 54px;
          height: 1px;
          margin: 0px;
          padding: 0px !important;
        }
      }
      p {
        width: 313px;
        margin: 0px 0px 0px 17px;
        font-size: 14px;
        font-family: Noto Sans SC;
        font-weight: 300;
        color: #ffffff;
        letter-spacing: 1px;
      }
      .btn-box {
        width: 100%;
        position: absolute;
        bottom: 43px;
        .btn {
          width: 315px;
          height: 55px;
          line-height: 55px;
          margin: 0px auto;
          background: #0091ff;
          border-radius: 33px;
          font-size: 21px;
          font-family: Noto Sans SC;
          font-weight: 600;
          color: #ffffff;
          text-align: center;
        }
        .to {
          display: block;
          width: 26px;
          height: 8px;
          margin: 20px auto auto auto;
          padding: 0px !important;
        }
      }
    }

    .advantage-container {
      display: none;
    }
    .advantage-container-mobile {
      display: block !important;
      width: 100%;
      height: 600px;
      box-sizing: border-box;
      position: relative;
      .title {
        width: 100%;
        text-align: center;
        position: absolute;
        top: 40px;
        left: 0px;
        .en-title {
          img {
            width: 121px;
            height: 24px;
          }
        }
        .cn-title {
          font-size: 15px;
          font-family: Noto Sans SC;
          font-weight: 800;
          color: #111111;
          margin-top: 0px;
        }
      }
      .advantage-list {
        width: 375px;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 25px auto auto auto;
        padding: 0px 15px;
        box-sizing: border-box;
        position: absolute;
        top: 112px;
        left: 50%;
        transform: translate(-50%, 0%);
        .advantage-item {
          width: 165px;
          height: 220px;
          box-sizing: border-box;
          padding: 10px 9px 15px 9px;

          background: #fff;
          border-radius: 12px;
          margin-bottom: 15px;

          &:nth-child(even) {
            margin-left: 15px;
          }

          img {
            width: 59px;
            height: 70px;
            margin: 0px !important;
            padding: 0px !important;
          }

          .content {
            h3 {
              font-size: 14px;
              font-family: Noto Sans SC;
              font-weight: 500;
              color: #222222;
              margin-top: 11px;
            }
            p {
              font-size: 12px;
              font-family: Noto Sans SC;
              font-weight: 300;
              color: #454545;
              line-height: 20px;
              margin-top: 7px;
            }
          }
        }
      }
    }

    .client-container {
      display: none !important;
    }
    .client-container-mobile {
      display: block !important;
      box-sizing: border-box;
      width: 100%;
      height: 600px;
      margin: 0px auto;
      background: #f6f9fa;
      padding: 0px 15px;
      position: relative;

      .client-mobile-bg {
        width: 345px;
        position: absolute;
        left: 50%;
        top: 98px;
        transform: translate(-50%, 0%);
      }

      .client {
        box-sizing: border-box;
        width: 345px !important;
        max-width: 345px !important;
        margin: 0px auto;
        padding-top: 26px;
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translate(-50%, 0%);
        .title {
          width: 100%;
          text-align: center;
          .en-title {
            img {
              width: 121px;
              height: 24px;
            }
          }
          .cn-title {
            font-size: 15px;
            font-family: Noto Sans SC;
            font-weight: 800;
            color: #111111;
            margin-top: 0px;
          }
        }
        .client-list {
          width: 100%;
          margin: 28px auto auto auto;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          box-sizing: border-box;
          padding: 25px 20px;
          // background: url("../assets/images/mobile/hezuoqiye1bg.png") no-repeat
          //   0px 0px;
          // background-size: 100%;
          .client-item {
            box-sizing: border-box;
            width: 144px;
            height: 97px;
            line-height: 97px;
            background: #ffffff;
            border-radius: 10px;
            text-align: center;
            margin-bottom: 15px;
            overflow: hidden;
            &:nth-child(even) {
              margin-left: 17px;
            }
            img {
              width: 100%;
              margin-top: 13.43px;
            }
          }
        }
      }
    }

    .job-container {
      display: none !important;
    }
    .footer-container {
      margin-top: 46px !important;
    }
  }
}
.ad-home {
  width: 100%;
  box-sizing: border-box;

  .banner-text-container {
    max-width: 1180px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    .banner-text {
      width: 686px;
      flex: 1;
      padding-top: 205px;
      .line {
        margin: 78px 0px 80px 0px;
      }
      p {
        font-size: 26px;
        font-family: Noto Sans SC;
        font-weight: 400;
        color: #ffffff;
        letter-spacing: 1px;
      }
    }
    .banner-download {
      padding-top: 562px;
      display: flex;
      align-items: center;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 16px;
        &:first-child {
          width: 60px;
          height: 60px;
          background: transparent;
          // background: #ffffff;
          // box-shadow: 0px 30px 28px 0px rgba(0, 0, 0, 0.1);
          // border-radius: 8px;
          // margin-left: 0px;
          // img {
          //   width: 24px;
          //   height: 24px;
          // }
        }
        &:nth-child(2) {
          width: 192px;
          height: 60px;
          background: #ffffff;
          box-shadow: 0px 30px 28px 0px rgba(0, 0, 0, 0.1);
          border-radius: 8px;

          font-size: 14px;
          font-weight: 300;
          color: #222222;
          img {
            width: 26px;
            height: 26px;
            margin-right: 10px;
          }
        }
        &:last-child {
          width: 192px;
          height: 60px;
          background: #ffffff;
          box-shadow: 0px 30px 28px 0px rgba(0, 0, 0, 0.1);
          border-radius: 8px;

          font-size: 14px;
          font-weight: 300;
          color: #222222;
          img {
            width: 26px;
            height: 26px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .banner-text-container-mobile {
    display: none;
  }

  .advantage-container {
    width: 100%;
    height: 760px;
    box-sizing: border-box;
    background: #f6f9fa url("../assets/images/icon-bg1.png") no-repeat center;
    background-size: cover;
    padding-top: 100px;
    .title {
      width: 100%;
      text-align: center;
      .en-title {
        img {
          width: 173px;
          height: 30px;
        }
      }
      .cn-title {
        font-size: 20px;
        font-family: Noto Sans SC;
        font-weight: 600;
        color: #111111;
        margin-top: 18px;
      }
    }
    .advantage-list {
      display: flex;
      align-items: flex-start;
      max-width: 1180px;
      margin: 80px auto auto auto;
      .advantage-item {
        width: 268px;
        height: 320px;
        box-sizing: border-box;
        padding: 18px;

        background: #ffffff;
        box-shadow: 0px 30px 28px 0px rgba(235, 241, 244, 0.66);
        border-radius: 20px;

        margin-left: 36px;

        &:first-child {
          margin-left: 0px;
        }

        img {
          width: 100px;
          height: 118px;
        }

        .content {
          h3 {
            font-size: 16px;
            font-family: Noto Sans SC;
            font-weight: 500;
            color: #222222;
            margin-top: 36px;
          }
          p {
            font-size: 14px;
            font-family: Noto Sans SC;
            font-weight: 400;
            color: #454545;
            line-height: 20px;
            margin-top: 16px;
          }
        }
      }
    }
  }
  .advantage-container-mobile {
    display: none;
  }

  .client-container {
    box-sizing: border-box;
    width: 100%;
    margin: 0px auto;
    background: #f6f9fa;

    .client {
      box-sizing: border-box;
      max-width: 1630px;
      height: 806px;
      margin: 0px auto;
      background: url("../assets/images/icon-bg2.png") no-repeat center;
      padding-top: 100px;
      .title {
        width: 100%;
        text-align: center;
        .en-title {
          img {
            width: 120px;
            height: 30px;
          }
        }
        .cn-title {
          font-size: 20px;
          font-family: Noto Sans SC;
          font-weight: 600;
          color: #111111;
          margin-top: 18px;
        }
      }
      .client-list {
        width: 1180px;
        margin: 60px auto auto auto;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        .client-item {
          width: 268px;
          height: 194px;
          line-height: 194px;
          background: #ffffff;
          border-radius: 20px;
          text-align: center;
          margin-bottom: 30px;
          &:nth-child(2),
          &:nth-child(6),
          &:nth-child(10),
          &:nth-child(14) {
            margin: 0px 36px;
          }
          &:nth-child(3),
          &:nth-child(7),
          &:nth-child(11),
          &:nth-child(15) {
            margin-right: 36px;
          }
          img {
            width: 180px;
            height: 90px;
            margin-top: 52px;
          }
        }
      }
    }
  }
  .client-container-mobile {
    display: none;
  }

  .job-container {
    width: 100%;
    box-sizing: border-box;
    padding-top: 100px;
    .title {
      text-align: center;
      .en-title {
        img {
          width: 195px;
          height: 30px;
        }
      }
      .cn-title {
        font-size: 20px;
        font-family: Noto Sans SC;
        font-weight: 600;
        color: #111111;
        margin-top: 18px;
      }
    }
    .job-list {
      max-width: 1180px;
      box-sizing: border-box;
      margin: 60px auto auto auto;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      .job-item {
        width: 572px;
        height: 174px;
        box-sizing: border-box;
        border-radius: 20px;
        border: 1px solid #e6e6e6;
        margin-bottom: 30px;
        cursor: pointer;
        padding: 36px 42px 38px 42px;
        &:nth-child(odd) {
          margin-right: 32px;
        }
        &:hover {
          background: #ffffff;
          box-shadow: 0px 24px 30px 0px rgba(235, 241, 244, 0.6);
          border: none;
          .icon {
            display: block !important;
          }
        }
        .job-name {
          display: flex;
          align-items: center;
          div {
            flex: 1;
            font-size: 16px;
            font-family: Noto Sans SC;
            font-weight: 600;
            color: #111111;
          }
          p {
            font-size: 20px;
            font-family: Noto Sans SC;
            font-weight: 900;
            color: #222222;
          }
        }
        .desc {
          font-size: 14px;
          font-family: Noto Sans SC;
          font-weight: 400;
          color: #999999;
          margin-top: 4px;
        }
        .company {
          display: flex;
          align-items: center;
          margin-top: 42px;
          .name {
            flex: 1;
            font-size: 14px;
            font-family: Noto Sans SC;
            font-weight: 400;
            color: #222222;
            display: flex;
            align-items: center;
            img {
              width: 16px;
              height: 16px;
              border-radius: 50%;
              margin-right: 7px;
            }
          }
          .icon {
            display: none;
            img {
              width: 18px;
              height: 10px;
            }
          }
        }
      }
    }
  }

  .footer-container {
    margin-top: 130px;
  }
}
</style>
